import React, { useEffect, useState } from "react";
import { Card, Form, Input } from "antd";
import { components } from "./formComponents";
import { fields } from "./formFields";
import { useTranslation } from "react-i18next";
import { UsersActions } from "../../../redux/users";
import { useDispatch } from "react-redux";

export const UserForm = ({
  onValidate,
  values,
  form,
  emailAvailable,
  emailLoading
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [available, setAvailable] = useState(true);

  useEffect(() => {
    if (values) {
      form.resetFields();
    }
  }, [values]);

  useEffect(() => {
    if (emailAvailable !== available) form.validateFields(["email"]);
    setAvailable(emailAvailable);
  }, [emailAvailable]);

  const onFinish = values => {
    if (onValidate) onValidate(values);
  };

  const requiredRules = ({ required, ...item }) => {
    let ret = {
      message: t("required_field"),
      required
    };
    if (!item.component) ret = { ...ret, whitespace: true };
    return ret;
  };

  const onFinishFailed = ({ errorFields }) => {
    const id = errorFields[0]?.name[0];
    if (id) {
      const el = document.getElementById(`${id}-form`);
      el.scrollIntoView();
    }
  };

  const checkEmailAvailable = email => {
    dispatch(UsersActions.emailRequest(email));
  };

  return (
    <Card style={{ margin: 25, width: "80%" }}>
      <Form
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        {fields(checkEmailAvailable, emailLoading, t).map(
          (
            {
              key,
              title,
              validateTrigger,
              valuePropName,
              noStyle,
              error,
              validator,
              validatorMail,
              errorMail,
              args,
              component
            },
            index
          ) => (
            <div id={`${key}-form`}>
              <Form.Item
                validateTrigger={validateTrigger || "onChange"}
                noStyle={noStyle}
                name={key}
                label={component !== "checkbox" && t(title)}
                valuePropName={valuePropName || "value"}
                rules={[
                  requiredRules(
                    fields(checkEmailAvailable, emailLoading, t)[index]
                  ),
                  () => ({
                    validator(rule, value) {
                      if (validator && !validator(value))
                        return Promise.reject(error || `${t(key)} incorrect`);
                      if (
                        errorMail &&
                        value &&
                        value !== values?.email &&
                        !emailAvailable
                      )
                        return Promise.reject(errorMail);
                      return Promise.resolve();
                    }
                  })
                ]}
              >
                {components(t)[component || "input"]({ key, title, args })}
              </Form.Item>
            </div>
          )
        )}
      </Form>
    </Card>
  );
};
