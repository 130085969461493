import React, { useEffect, useState } from "react";
import { AuthLayout } from "../../layouts";
import { Row, Button } from "antd";
import { Filter } from "../../components/filter";
import { UsersTable } from "./usersTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { UsersActions } from "../../../redux/users";
import "./style.css";

export const Users = ({}) => {
  const [reset, setReset] = useState(false);
  const [valueList, setValueList] = useState([]);

  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const { users, current_user, searchValues, searchValuesResult } = useSelector(
    state => state.users
  );

  const { carrefour_id } = current_user;

  useEffect(() => {
    if (carrefour_id) dispatch(UsersActions.getUsersRequest(carrefour_id));
  }, [carrefour_id]);

  useEffect(() => {
    if (users) setValueList(users);
  }, [users]);

  useEffect(() => {
    setValueList(searchValuesResult || users);
  }, [searchValuesResult]);

  const onCreate = () => {
    navigateTo("/users/add");
  };

  const onFilter = (result, value) => {
    if (!result) setReset(!reset);
    dispatch(
      UsersActions.changeSearchValues(
        result,
        value !== undefined ? value : null
      )
    );
  };

  return (
    <AuthLayout current={"3"} bread={"Users:"}>
      <Row justify={"space-between"}>
        <Filter
          list={users}
          onFilter={onFilter}
          keys={["firstname", "lastname", "email"]}
          searchValues={searchValues}
          placeHolder={t("users_placeholder_filter")}
        />
        <div className="separate-filter-div" />
        <div className="create-users-container">
          <Button
            id={"add_user"}
            key={"create-users"}
            type="primary"
            onClick={onCreate}
          >
            {t("users_add_user")}
          </Button>
        </div>
      </Row>
      <UsersTable valueList={valueList} />
    </AuthLayout>
  );
};
