import {
  LEVEL_OF_STUDY,
  LABOUR_MARKET_SITUATION,
  QPV,
  RECEPTION_MODALITY,
  PRESCRIBER,
  TYPE_OBSTACLES_ENCOUNTRED,
  PROJECT_MATURITY,
  PROJECT_ACTIVITY_SECTOR
} from "../../../utils/beneficiariesFields";

export const fields = (emailRequest, emailLoading, referent, t) => {
  return [
    {
      key: "referent_id",
      title: "beneficiarie_create_form_referent",
      placeholder: t("beneficiarie_create_form_referent_placeholder"),
      component: "select",
      args: referent
    },
    {
      key: "firstname",
      title: "beneficiarie_create_form_firstname",
      required: true
    },
    {
      key: "lastname",
      title: "beneficiarie_create_form_lastname",
      required: true
    },
    {
      key: "email",
      title: "beneficiarie_create_form_email",
      required: true,
      args: emailLoading,
      error: t("beneficiarie_create_form_email_format"),
      errorMail: t("beneficiarie_create_user_form_email_not_available"),
      validator: item => {
        const ret = !item ? true : item.match(/\S+@\S+\.\S+/g);
        if (item && ret) {
          emailRequest(item);
        }
        return ret;
      }
    },
    {
      key: "phone_number",
      title: "beneficiarie_create_form_phone_number",
      required: true,
      error: t("beneficiarie_create_form_phone_number_format"),
      validator: item => {
        const ret = !item ? true : item.match(/^(\+33|0033|0)(6|7)[0-9]{8}$/g);
        return ret;
      }
    },
    {
      key: "genre",
      title: "beneficiarie_create_form_genre",
      placeholder: t("beneficiarie_create_form_genre_placeholder"),
      component: "select",
      required: true,
      args: [
        { label: t("dashboard_beneficiaries_man"), value: "male" },
        { label: t("dashboard_beneficiaries_woman"), value: "female" }
      ]
    },
    {
      key: "date_of_birth",
      title: "beneficiarie_create_form_date_of_birth",
      placeholder: t("beneficiarie_create_form_date_of_birth_placeholder"),
      required: true,
      component: "datepicker"
    },
    {
      key: "postal_code",
      title: "beneficiarie_create_postal_code_placeholder",
      error: t("beneficiarie_create_postal_code_format"),
      validator: item => {
        const ret = !item
          ? true
          : item.match(/^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/g);
        return ret;
      }
    },
    {
      key: "level_of_study",
      title: "beneficiarie_create_form_level_of_study",
      placeholder: t("beneficiarie_create_form_level_of_study_placeholder"),
      component: "select",
      required: true,
      args: LEVEL_OF_STUDY
    },
    {
      key: "labour_market_situation",
      title: "beneficiarie_create_form_labour_market_situation",
      placeholder: t(
        "beneficiarie_create_form_labour_market_situation_placeholder"
      ),
      component: "select",
      required: true,
      args: LABOUR_MARKET_SITUATION
    },
    {
      key: "handicapped_worker",
      title: "beneficiarie_create_form_handicapped_worker",
      placeholder: t("beneficiarie_create_form_handicapped_worker_placeholder"),
      component: "select",
      required: true,
      args: [
        { label: t("general_yes"), value: true },
        { label: t("general_no"), value: false }
      ]
    },
    {
      key: "is_qpv",
      title: "beneficiarie_create_form_is_qpv",
      placeholder: t("beneficiarie_create_form_is_qpv_placeholder"),
      component: "select",
      args: [
        { label: t("general_yes"), value: true },
        { label: t("general_no"), value: false }
      ]
    },
    {
      key: "qpv",
      title: "beneficiarie_create_form_qpv",
      placeholder: t("beneficiarie_create_form_qpv_placeholder"),
      component: "select",
      args: QPV
    },
    {
      key: "reception_modality",
      title: "beneficiarie_create_form_reception_modality",
      placeholder: t("beneficiarie_create_form_reception_modality_placeholder"),
      component: "select",
      required: true,
      args: RECEPTION_MODALITY
    },
    {
      key: "prescriber",
      title: "beneficiarie_create_form_prescriber",
      placeholder: t("beneficiarie_create_form_prescriber_placeholder"),
      component: "select",
      required: true,
      args: PRESCRIBER
    },
    {
      key: "obstacles",
      title: "beneficiarie_create_form_obstacles",
      placeholder: t("beneficiarie_create_form_is_obstacles_placeholder"),
      component: "select",
      args: [
        { label: t("general_yes"), value: true },
        { label: t("general_no"), value: false }
      ]
    },
    {
      key: "type_obstacles_encountred",
      title: "beneficiarie_create_form_type_obstacles_encountred",
      placeholder: t(
        "beneficiarie_create_form_is_type_obstacles_encountred_placeholder"
      ),
      component: "select",
      args: TYPE_OBSTACLES_ENCOUNTRED
    },
    {
      key: "project_name",
      title: "beneficiarie_create_form_project_name"
    },
    {
      key: "project_maturity",
      title: "beneficiarie_create_form_project_maturity",
      placeholder: t(
        "beneficiarie_create_form_is_project_maturity_placeholder"
      ),
      component: "select",
      required: true,
      args: PROJECT_MATURITY
    },
    {
      key: "project_activity_sector",
      title: "beneficiarie_create_form_project_activity_sector",
      placeholder: t(
        "beneficiarie_create_form_is_project_activity_sector_placeholder"
      ),
      component: "select",
      required: true,
      args: PROJECT_ACTIVITY_SECTOR
    },
    {
      key: "project_description",
      title: "beneficiarie_create_form_project_description",
      component: "textarea"
    },
    {
      key: "commentary",
      title: "beneficiarie_create_form_commentary",
      component: "textarea"
    },
    {
      key: "received_free_accommodation_carrefour",
      title: "beneficiarie_create_received_free_accomodation",
      component: "checkbox",
      valuePropName: "checked"
    },
    {
      key: "mentor",
      title: "beneficiarie_create_has_mentor",
      component: "checkbox",
      valuePropName: "checked"
    },
    {
      key: "mentor_identity",
      title: "beneficiarie_create_mentor_identity",
      component: "textarea"
    },
    {
      key: "received_contract_by_contract_window",
      title: "beneficiarie_create_received_contract_by_contract_window",
      component: "checkbox",
      valuePropName: "checked"
    }
  ];
};
