import { createReducer } from "reduxsauce";
import moment from "moment";
import { types } from "./actions";
import { TYPES } from "../../ui/screens/beneficiarieCreate/roadmap/RenderMappers";

const initialState = {
  beneficiaries: [],
  roadmap: [],
  pageSize: 10,
  current: 1,
  filters: {},
  searchValues: null,
  searchValuesResult: null,
  record: null,
  sorterFilter: null,
  available: true,
  loading: false
};

const getBeneficiaries = (state = initialState, action) => {
  const { beneficiaries } = action;

  let tmp = [];
  beneficiaries.map(item => {
    item.genre === "male" ? (item.genre = "Homme") : (item.genre = "Femme");
    item.age = moment().diff(item.date_of_birth, "years");
    tmp.push(item);
  });

  return { ...state, beneficiaries: tmp };
};

const beneficiariesDeleteSuccess = (state = initialState, action) => {
  const { beneficiaries } = state;
  const { ids } = action;
  const beneficiaries_ids = ids.map(item => item.id);
  const tmp = beneficiaries?.filter(
    item => !beneficiaries_ids.includes(item.id)
  );
  return { ...state, beneficiaries: tmp };
};

const getBeneficiariesRoadmapSuccess = (state = initialState, action) => {
  const { roadmap, orientations } = action;
  let tmp = [];

  roadmap.events.map(i => {
    tmp.push({ ...i, date: i.start_date, type: TYPES.EVENTS });
  });

  roadmap.orientations_beneficiaries.map(i => {
    const { label } = orientations.find(
      item => item.value === i.orientations_id
    );
    tmp.push({ ...i, to: label, date: i.created_at, type: TYPES.ORIENTATIONS });
  });

  roadmap.notes.map(i => {
    tmp.push({ ...i, type: TYPES.NOTES });
  });

  tmp.sort(function(a, b) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return { ...state, roadmap: tmp };
};

const insertRoadmapSuccess = (state = initialState, action) => {
  const { roadmap } = state;
  const { array, roadmap_type } = action;

  const tmp = roadmap;

  tmp.push({
    ...array,
    date: roadmap_type === TYPES.NOTES ? array.date : array.created_at,
    type: roadmap_type
  });

  tmp.sort(function(a, b) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return { ...state, roadmap: tmp };
};

const resetRoadmap = (state = initialState) => {
  return { ...state, roadmap: [] };
};

const changeTablePagesize = (state = initialState, action) => {
  const { current, pageSize, record, sorterFilter } = action;

  return {
    ...state,
    current: current,
    pageSize: pageSize,
    record: record,
    sorterFilter: sorterFilter
  };
};

const changeBeneficiariesFilters = (state = initialState, action) => {
  const { filters } = action;

  return { ...state, filters: filters };
};

const changeSearchValues = (state = initialState, action) => {
  const { result, searchValues } = action;

  return {
    ...state,
    searchValuesResult: result,
    searchValues: searchValues
  };
};

const resetFilters = (state = initialState) => {
  return {
    ...state,
    filters: {},
    searchValues: null,
    searchValuesResult: null
  };
};

const emailLoading = (state = initialState, action) => {
  const { loading } = action;

  return { ...state, loading };
};

const emailSuccess = (state = initialState, action) => {
  const { available } = action;
  return { ...state, available };
};

export default createReducer(initialState, {
  [types.GET_BENEFICIARIES_SUCCESS]: getBeneficiaries,
  [types.BENEFICIARIES_DELETE_SUCCESS]: beneficiariesDeleteSuccess,
  [types.GET_BENEFICIARIES_ROADMAP_SUCCESS]: getBeneficiariesRoadmapSuccess,
  [types.INSERT_ROADMAP_SUCCESS]: insertRoadmapSuccess,
  [types.RESET_ROADMAP]: resetRoadmap,
  [types.BENEFICIARIES_CHANGE_TABLE_PAGESIZE]: changeTablePagesize,
  [types.BENEFICIARIES_CHANGE_BENEFICIARIES_FILTERS]: changeBeneficiariesFilters,
  [types.BENEFICIARIES_CHANGE_SEARCH_VALUES]: changeSearchValues,
  [types.RESET_FILTERS]: resetFilters,
  [types.CHECK_BENEFICIARIES_EMAIL_SUCCESS]: emailSuccess,
  [types.BENEFICIARIES_EMAIL_LOADING]: emailLoading
});
