import React, { useState, useEffect } from "react";
import { columns } from "./columnsTable";
import { DataTable } from "../../components";
import { Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { actionsRender } from "./actionRender";
import { useNavigate } from "react-router-dom";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
import { onScrollTop } from "../../../utils/scrollTop";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { UsersActions } from "../../../redux/users";
import { TagsActions } from "../../../redux/tags";

export const TagsTable = ({
  valueList,
  selectedRowKeys,
  setSelectedRowKeys
}) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { current, pageSize, sorterFilter } = useSelector(state => state.tags);

  const onPaginationChange = (current, pageSize, record, sorterFilter) => {
    dispatch(TagsActions.changeTablePagesize(current, pageSize, sorterFilter));
  };

  const handleReset = () => {
    setSelectedRowKeys([]);
  };

  const onModify = id => {
    navigateTo(`/tag/${id}`);
  };

  const onDelete = ids => {
    dispatch(TagsActions.tagsDeleteRequest(ids));
    setSelectedRowKeys([]);
  };

  const handleOnActionRender = () => {
    return actionsRender(handleReset, onDelete, selectedRowKeys, t);
  };

  return (
    <div>
      <DataTable
        items={valueList}
        columns={columns(onModify, onDelete, sorterFilter, t)}
        actionsRender={handleOnActionRender}
        setSelectedRowKeys={setSelectedRowKeys}
        selectedRowKeys={selectedRowKeys}
        current={current}
        pageSize={pageSize}
        onPaginationChange={onPaginationChange}
      />
      <Row className="user-table-button-container">
        <Button
          type="primary"
          shape="circle"
          icon={<VerticalAlignTopOutlined />}
          onClick={onScrollTop}
        />
      </Row>
    </div>
  );
};
