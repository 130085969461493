import { default as BeneficiariesActions, types } from "./actions";
import { call, put, all, takeLatest, delay, select } from "redux-saga/effects";
import {
  BeneficiariesService,
  EventsService,
  OrientationsService
} from "../../services";
import { LoaderActions } from "../loader";
import i18next from "i18next";
import { showError, showSuccess } from "../../utils/notifications-helper";
import { get } from "lodash";
import { ROUTES } from "../../core/router";
import { history } from "../../core/history";
import download from "downloadjs";
import moment from "moment";
import { TYPES } from "../../ui/screens/beneficiarieCreate/roadmap/RenderMappers";

function* getBeneficiariesRequest({ carrefour_id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(BeneficiariesService.getbeneficiaries, {
    carrefour_id
  });
  if (response) {
    yield put(
      BeneficiariesActions.getBeneficiariesSuccess(
        get(response, "beneficiaries", [])
      )
    );
    yield put(LoaderActions.loaded());
    return;
  }
  showError("request_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* insertBeneficiariesRequest({ data, request }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    BeneficiariesService.insertBeneficiaries,
    data
  );
  if (response) {
    showSuccess("beneficiarie_create_success", i18next.t);
    request.map(
      i =>
        (i.data.beneficiaries_id = get(
          response,
          ["insert_beneficiaries", "returning", 0, "id"],
          0
        ))
    );
    const results = yield all(
      request.map(({ service, data }) => call(service, data))
    );
    const hasError = results.some(res => res[0]);
    if (hasError) {
      showError("beneficiarie_roadmap_error", i18next.t);
    }
    yield call(history.push, ROUTES.DASHBOARD);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("beneficiarie_create_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* updateBeneficiariesRequest({ id, data, request }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    BeneficiariesService.updateBeneficiaries,
    { id, data }
  );
  if (response) {
    showSuccess("beneficiarie_update_success", i18next.t);
    const results = yield all(
      request.map(({ service, data }) => call(service, data))
    );
    const hasError = results.some(res => res[0]);
    if (hasError) {
      showError("beneficiarie_update_tags_failed", i18next.t);
      yield put(LoaderActions.loaded());
      return;
    }
    showSuccess("beneficiarie_update_tags_success", i18next.t);
    yield call(history.push, ROUTES.DASHBOARD);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("beneficiarie_update_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* beneficiariesDeleteRequest({ ids }) {
  yield put(LoaderActions.loading());

  const [error, response] = yield call(
    BeneficiariesService.deleteBeneficiaries,
    ids
  );
  if (response) {
    yield put(
      BeneficiariesActions.beneficiariesDeleteSuccess(
        get(response, ["delete_beneficiaries", "returning"], [])
      )
    );
    showSuccess("beneficiaries_delete_success", i18next.t);
    yield call(history.push, ROUTES.DASHBOARD);
    yield put(LoaderActions.loaded());
    return;
  }
  showError("beneficiaries_delete_failed", i18next.t);
  yield put(LoaderActions.loaded());
}

function* exportBeneficaries({ valueList, carrefour_id }) {
  yield put(LoaderActions.loading());
  const { orientations, exact_structure } = yield select(
    state => state.orientations
  );

  const tmp = [...valueList].map(item => ({
    ...item,
    orientations_beneficiaries: item.orientations_beneficiaries
      .map(i => {
        const res = orientations.find(o => o.value === i.orientations_id);
        let tmp = Object.assign({}, res);

        tmp.label = res.label;
        return tmp;
      })
      .slice(-1)
  }));

  const [error, response] = yield call(
    BeneficiariesService.exportBeneficiaries,
    {
      beneficiaries: tmp,
      carrefour_id: carrefour_id
    }
  );
  if (response) {
    download(
      response.data,
      `export-beneficiaire-${moment().format("DD_MM_YYYY")}.xlsx`,
      {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }
    );
  } else {
    showError("beneficiaries_export_failed", i18next.t);
  }
  yield put(LoaderActions.loaded());
}

function* getBeneficiariesRoadmapRequest({ id }) {
  yield put(LoaderActions.loading());
  const { orientations } = yield select(state => state.orientations);
  const [error, response] = yield call(
    BeneficiariesService.beneficiariesRoadmap,
    {
      id
    }
  );
  if (response) {
    yield put(
      BeneficiariesActions.getBeneficiariesRoadmapSuccess(
        response,
        orientations
      )
    );
    yield put(LoaderActions.loaded());
    return;
  }
  showError("request_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* insertBeneficiariesNoteRequest({ data }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    BeneficiariesService.insertBeneficiariesNote,
    data
  );
  if (response) {
    showSuccess("roadmap_insert_note_success", i18next.t);
    yield put(
      BeneficiariesActions.insertRoadmapSuccess({
        array: get(response, "insert_notes_one", {}),
        roadmap_type: TYPES.NOTES
      })
    );
    yield put(LoaderActions.loaded());
    return;
  }
  showError("roadmap_insert_note_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* insertBeneficiariesEventsRequest({ beneficiaries_id, events_id }) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(
    EventsService.insertBeneficiariesEvents,
    {
      beneficiaries_id: beneficiaries_id,
      events_id: events_id,
      participates: false
    }
  );
  if (response) {
    showSuccess("roadmap_insert_event_success", i18next.t);
    yield put(
      BeneficiariesActions.insertRoadmapSuccess({
        array: get(response, ["insert_events_beneficiaries_one", "event"], {}),
        roadmap_type: TYPES.EVENTS
      })
    );
    yield put(LoaderActions.loaded());
    return;
  }
  showError("roadmap_insert_event_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* insertBeneficiariesOrientationsRequest({ data }) {
  yield put(LoaderActions.loading());
  const { orientations } = yield select(state => state.orientations);
  const [error, response] = yield call(
    OrientationsService.insertOrientations,
    data
  );
  if (response) {
    showSuccess("roadmap_insert_orientations_success", i18next.t);
    let tmp = {
      ...get(response, ["insert_orientations_beneficiaries_one"], {})
    };
    tmp.to = orientations.find(
      item => item.value === tmp.orientations_id
    ).label;
    yield put(
      BeneficiariesActions.insertRoadmapSuccess({
        array: tmp,
        roadmap_type: TYPES.ORIENTATIONS
      })
    );
    yield put(LoaderActions.loaded());
    return;
  }
  showError("roadmap_insert_orientations_failure", i18next.t);
  yield put(LoaderActions.loaded());
}

function* checkBeneficiariesEmail({ email }) {
  yield put(BeneficiariesActions.beneficiariesEmailLoading(true));
  const [error, response] = yield call(
    BeneficiariesService.checkBeneficiariesEmail,
    email.trim()
  );
  if (response) {
    yield put(
      BeneficiariesActions.checkBeneficiariesEmailSuccess(
        get(response, ["beneficiaries"], []).length < 2
      )
    );
  }
  yield put(BeneficiariesActions.beneficiariesEmailLoading(false));
}
export default [
  takeLatest(types.GET_BENEFICIARIES_REQUEST, getBeneficiariesRequest),
  takeLatest(types.INSERT_BENEFICIARIES_REQUEST, insertBeneficiariesRequest),
  takeLatest(types.UPDATE_BENEFICIARIES_REQUEST, updateBeneficiariesRequest),
  takeLatest(types.BENEFICIARIES_DELETE_REQUEST, beneficiariesDeleteRequest),
  takeLatest(types.EXPORT_BENEFICIARIES, exportBeneficaries),
  takeLatest(
    types.GET_BENEFICIARIES_ROADMAP_REQUEST,
    getBeneficiariesRoadmapRequest
  ),
  takeLatest(
    types.INSERT_BENEFICIARIES_NOTE_REQUEST,
    insertBeneficiariesNoteRequest
  ),
  takeLatest(
    types.INSERT_BENEFICIARIES_EVENTS_REQUEST,
    insertBeneficiariesEventsRequest
  ),
  takeLatest(
    types.INSERT_BENEFICIARIES_ORIENTATIONS_REQUEST,
    insertBeneficiariesOrientationsRequest
  ),
  takeLatest(types.CHECK_BENEFICIARIES_EMAIL_REQUEST, checkBeneficiariesEmail)
];
